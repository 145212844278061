.homeHeading {
  text-align: center;
  background: linear-gradient(94deg, #000 19.84%, #4e5ee4 105.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-delay: 5s;
  animation: falldownTxt1 1s ease-in-out;
  width: 70%;
  font-size: 37px;
  font-family: "Urbanist";
}

@media screen and (max-width: 900px) {
  .homeHeading {
    font-size: 32px;
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .homeHeading {
    font-size: 24px;
    width: 90%;
  }
}

@keyframes falldownTxt1 {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.howWeWork {
  background: linear-gradient(180deg, #0069ec 0%, #3986e5 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 25px;
  font-weight: 500;
  font-family: "Urbanist";
  border-radius: 30px;
  margin-top: 48px;
  animation: button 1.5s;
  text-decoration: none;
  margin-bottom: 20px;
}

@keyframes button {
  from {
    font-size: 10px;
  }
  to {
    font-size: 18px;
  }
}

.ourMissionText {
  color: #000000;
  font-family: "Urbanist";
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: -10px;
}

@media screen and (max-width: 1200px) {
  .ourMissionText {
    font-size: 22px;
  }
}
@media screen and (max-width: 900px) {
  .ourMissionText {
    font-size: 20px;
    text-align: center;
    margin-top: 24px;
  }
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .ourMissionText {
    width: 100%;
    text-align: left;
  }
}
